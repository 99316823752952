<template>
  <div class="content-transation-detial">
    <div class="global-content-detail" v-if="bSkeleton">
      <skeleton-searchbar-transation-detail-component
        v-if="bSkeleton"
        class="global-searchbar-detail"
      />
      <skeleton-Transation-detail-component />
    </div>
    <div class="global-content-detail" v-else>
      <div ref="searchTransation" v-if="!bSkeleton">
        <searchbar-transation-detail-component
          class="global-searchbar-detail"
          :oItem="oItemInformationDate"
        />
        <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
      </div>
      <!--#region DATOS INFORMATIVOS -->
      <div class="content-identification-information">
        <p class="text-identification-information">Datos informativos</p>
        <v-container fluid>
          <v-row>
            <!-- #region LABELS AND TEXTS-FIELDS  -->

            <!-- #region IDENTIFICADOR DE TRANSACCION  -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <!-- v-if="!bModify" -->
              <div>
                <p class="text-title">Identificador de transacción</p>
                <p class="text-information">
                  {{ oItemInformationDate.sFolio }}
                </p>
              </div>
            </v-col>
            <!-- #endregion IDENTIFICADOR DE TRANSACCION  -->

            <!-- #region TIPO  DE TRANSACCIÓN  -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">Tipo de transacción</p>
                <p class="text-information">
                  {{ oItemInformationDate.sTransactionType }}
                </p>
              </div>
            </v-col>
            <!-- #endregion TIPO  DE TRANSACCIÓN -->

            <!-- #region CANTIDAD ASOCIADA -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">Cantidad asociada</p>
                <p class="text-information">
                  $ {{ formatMoneyGlobal(oItemInformationDate.dAmount) }}
                  {{ sCurrencyCode }}
                </p>
              </div>
            </v-col>
            <!-- #endregion CANTIDAD ASOCIADA -->

            <!-- #region Nombre del beneficiario -->
            <v-col v-if="oItemInformationDate.sBeneficiaryName" cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">Nombre del beneficiario</p>
                <p class="text-information">
                  {{ oItemInformationDate.sBeneficiaryName }}
                </p>
              </div>
            </v-col>
            <!-- #endregion Nombre del beneficiario -->

            <!-- #region TIPO DE REGISTRO -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">Tipo de registro</p>
                <p class="text-information">
                  {{ oItemInformationDate.eRegistrationType }}
                </p>
              </div>
            </v-col>
            <!-- #endregion TIPO DE REGISTRO -->

            <!-- #region FECHA DE  TRANSACCIÓN -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">Fecha de transacción</p>
                <p class="text-information">
                  {{ oItemInformationDate.tTransactionDate }}
                </p>
              </div>
            </v-col>
            <!-- #endregion FECHA DE TRANSACCIÓN -->

            <!-- #region INGRESO DE TRNAS. EN EL SISTEMA -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">Fecha de registro</p>
                <p class="text-information">
                  {{ oItemInformationDate.created_at }}
                </p>
              </div>
            </v-col>
            <!-- #endregion INGRESO DE TRNAS. EN EL SISTEMA -->

            <!-- #region DESCRIPCIÓN DE LA TRANSACCION -->
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <div>
                <p class="text-title">Descripción</p>
                <p class="text-information">
                  {{ oItemInformationDate.sDescription }}
                </p>
              </div>
            </v-col>
            <!-- #endregion DESCRIPCIÓN DE LA TRANSACCION -->

            <!-- #endregion LABELS TEXTS-FIELDS -->
          </v-row>
        </v-container>
        <v-divider class="divider-global mt-9"></v-divider>
      </div>
      <!--#endregion DATOS INFORMATIVOS -->

      <!--#region INFORMACIÓN DEL RESPONSABLE  -->
      <div class="content-identification-information">
        <p class="text-identification-information">
          Información del responsable
        </p>
        <v-container fluid>
          <v-row>
            <!-- #region LABELS AND TEXTS-FIELDS   -->
            <!-- #region EMPLEADO QUE REALIZO LA TRANS  -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <!-- v-if="!bModify" -->
              <div>
                <p class="text-title">Registrada por</p>
                <p class="text-information">
                  <span
                    @click="goToUser(oItemResponsableInfromation.sUserId)"
                    class="text-information-link"
                  >
                    {{ oItemResponsableInfromation.sUserFullName }}
                  </span>
                  <v-icon
                    @click="goToUser(oItemResponsableInfromation.sUserId)"
                    class="text-icon-launch"
                    >mdi-launch
                  </v-icon>
                </p>
              </div>
            </v-col>
            <!-- #endregion IDENTIFICADOR DE TRANSACCION  -->

            <!-- #region TELEFONO DEL RESPONSABLE  -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">Teléfono del responsable</p>
                <p class="text-information">
                  {{ oItemResponsableInfromation.sUserPhoneNumber }}
                </p>
              </div>
            </v-col>
            <!-- #endregion TIPO  DE TRANSACCIÓN -->

            <!-- #region CORREO ELECTRONICO -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">Correo electrónico</p>
                <p class="text-information">
                  {{ oItemResponsableInfromation.sUserEmail }}
                </p>
              </div>
            </v-col>
            <!-- #endregion CANTIDAD ASOCIADA -->

            <!-- #region CLIENTE ASOCIADO -->
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <div>
                <p class="text-title">Cliente asociado</p>
                <p
                  v-if="oItemResponsableInfromation.AsocCustomer"
                  class="text-information"
                >
                  {{ oItemResponsableInfromation.AsocCustomer }}
                </p>
                <p class="text-data-empty">
                  {{ sEmptyDataGlobal }}
                </p>
              </div>
            </v-col>
            <!-- #endregion TIPO DE REGISTRO -->

            <!-- #endregion LABELS TEXTS-FIELDS  -->
          </v-row>
        </v-container>
        <v-divider class="divider-global mt-9"></v-divider>
      </div>
      <!--#endregion INFORMACIÓN DEL RESPONSABLE -->
    </div>
    <!-- <footer-component-global /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,
      bSkeleton: true,
      oItemInformationDate: {},
      oItemResponsableInfromation: {},
      itemsBreadcrumbs: [],
      sCurrencyCode: "",
    };
  },
  mounted() {
    this.matchHeight();
    this.handleResize();
  },
  updated() {
    this.matchHeight();
  },
  beforeMount() {
    this.$store
      .dispatch("getPermissionsByUserGlobal")
      .then((resp) => {
        this.setNameBreadcrumbs();
        this.getTransationDetail();
      })
      .catch((err) => {
        this.Error(err);
      });
  },
  created() {
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.addEventListener("resize ", this.matchHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    setNameBreadcrumbs() {
      let aBreadcrumbs = [
        {
          text: "Cuentas financieras",
          disabled: false,
          to: { name: "Account" },
        },
        {
          text: "Detalle de cuenta financiera",
          link: true,
          exact: true,
          disabled: false,
          to: { name: "AccountDetail" },
          params: { id: this.$route.params.id },
        },
        {
          text: "Detalle de transacción",
          link: true,
          exact: true,
          disabled: true,
          // to: { name: "TransationDetail" },
          // params: { id: this.$route.params.idTransation },
        },
      ];
      this.itemsBreadcrumbs = aBreadcrumbs;
    },
    matchHeight() {
      if (this.$refs.searchTransation !== undefined) {
        this.heightMax = this.$refs.searchTransation.clientHeight;
        this.heightMax = this.heightMax + 123;
      }
      if (window.innerWidth > 600) {
        this.screenHeight = window.innerHeight - 100;
      } else {
        this.screenHeight = window.innerHeight - 100;
      }
    },
    handleResize: function () {
      this.matchHeight();
    },
    colorTransationType(sTransactionType) {
      if (sTransactionType === "DEPOSIT") {
        return "#00A85B";
      } else {
        return "#E85353";
      }
    },
    goToUser(sUserId) {
      this.$router
        .push(
          `/home/account-detail/${this.$route.params.id}/transation/${this.$route.params.idTransation}/user-profile-detail-account/${sUserId}`
        )
        .catch(() => {});
    },
    getTransationDetail() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      DB.get(
        `${URI}/api/sp/v1/accounts/${this.$route.params.id}/transactions/${this.$route.params.idTransation}`,
        config,
        payload
      )
        .then((response) => {
          this.oItemInformationDate = response.data.results;
          this.sCurrencyCode = this.oItemInformationDate.oCurrency.sCode;
          this.oItemResponsableInfromation = response.data.results.oUser;
          this.$store.commit(
            "setsNameDetail",
            this.oItemInformationDate.sFolio
          );
          this.bSkeleton = false;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
  },
  computed: {
    sEmptyDataGlobal() {
      return this.$store.state.sEmptyDataGlobal;
    },
  },
};
</script>

<style scoped>
.content-identification-information {
  margin-bottom: 25px;
  padding: 0px 0px 0px 12px;
}

.text-identification-information {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 16px;
  letter-spacing: 3px;
}

.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}

.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}

.content-btns-accions {
  display: flex;
  padding: 0px 0px 0px 12px;
  margin-top: 25px;
  margin-bottom: 5px;
  width: 100% !important;
}

.content-btn-primary {
  width: 200px;
}

.content-btn-second {
  width: 200px;
  margin-right: 15px;
}

.text-identification {
  margin-bottom: 0px;
  color: var(--primary-color-text);
}

.text-icon-launch {
  color: var(--primary-color-link);
  /* margin-top: -5px; */
  font-size: 20px;
  cursor: pointer;
}
</style>
